export const orders = [
    {
        id: 498374987324,
        truck: 3678,
        location: 'Tucson, AZ'
    },
    {
        id: 873264382746,
        truck: 4432,
        location: 'Suprise, AZ'
    },
]

export const testAccount = {
    id: 444,
    username: 'Lee',
    email: 'test@smileycrane.com',
    phone: 6024519511,
}

export const inventory = [
    {
        name: 'Stake Bed Delivery Truck',
        radius: 0,
        cost: 99,
        truckID: 4432,
        img: 'https://smileycrane.com/wp-content/uploads/2018/09/IMG_0423.jpg'
    },
    {
        name: '5 Ton Boom Truck',
        radius: 70,
        cost: 105,
        truckID: 6665,
        img: 'https://smileycrane.com/wp-content/uploads/2016/03/5-ton-84RM.jpg'
    },
    {
        name: '17 Ton Boom Truck',
        radius: 80,
        cost: 130,
        truckID: 3321,
        img: 'https://smileycrane.com/wp-content/uploads/2018/06/20180309_110020.jpg'
    },
    {
        name: '35 Ton Boom Truck',
        radius: 90,
        cost: 155,
        truckID: 5433,
        img: 'https://smileycrane.com/wp-content/uploads/2018/09/Photo-Apr-13-8-01-22-AM-e1536786278836.jpg'
    },
    {
        name: '45 Ton Boom Truck',
        radius: 110,
        cost: 180,
        truckID: 3678,
        img: 'https://smileycrane.com/wp-content/uploads/2018/06/IMG_0726-300x225.jpg'
    }
]