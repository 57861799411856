import { useReducer, useState } from "react";
import { SmileyMain } from "../components/Smiley/SmileyMain";
import { SmileyNav } from "../components/Smiley/SmileyNav";

interface Login {
    username: string;
    password: string;
}
export const Smiley = () => {

    const [showLogin, setShowLogin] = useState(true);
    const [ login, updateLogin ] = useReducer((currentData: Login, newData: Partial<Login>) => {
        return { ...currentData, ...newData }
    }, {username: '', password: ''})

    const logoURL = 'https://smileycrane.com/wp-content/uploads/2016/03/Smiley-Crane-Logo_2016Web.png';
    const canLogin = login.username && login.password;

    return <div className="w-screen h-screen flex items-center justify-center bg-stone-900">
        <div className="sm:aspect-[9/16] relative bg-white rounded w-full h-full sm:h-[80vh] sm:w-auto">
            {showLogin ? <div className="w-full h-full flex items-center justify-center flex-col gap-3">
                <img src={logoURL} />
                <input value={login.username} className="w-[80%] p-2 focus:outline-none rounded bg-stone-200 border border-stone-300" placeholder="Username" onChange={(e) => updateLogin({username: e.target.value})}/>
                <input value={login.password} type="password" className="w-[80%] p-2 focus:outline-none rounded bg-stone-200 border border-stone-300" placeholder="Password" onChange={(e) => updateLogin({password: e.target.value})}/>
                {canLogin && <div className="bg-blue-300 rounded p-3 text-center font-bold w-[80%] cursor-pointer hover:bg-blue-300/80 transition-all text-white" onClick={() => setShowLogin(false)}>Login</div>}
                <div className="text-sm text-stone-400 cursor-pointer hover:text-blue-300 transition-all">Don't have an account? | Forgot Password</div>
            </div> :
            <div>
                <div className="overflow-auto max-h-[80vh]">
                <SmileyMain/>
                </div>
                <SmileyNav/>
            </div>}
        </div>
    </div>
}