import { useState, useEffect } from 'react';
import { Dock } from '../components/Dock';
import { LoginScreen } from '../components/LoginScreen';
import { SafariScreen } from '../components/SafariScreen';
import { useApp } from '../context/appContext';
//@ts-ignore
import bg from '../Documents/mainbg.webp';

export const Main = () => {
    
  const { safari, user } = useApp();
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    if(!user) return;
    setTimeout(() => setLoading(false), 4500)
  }, [user])

  const [hasActiveScreen] = safari.filter(e => e.active)


  return (
    <div className="bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-400 w-screen h-screen p-1 lg:p-10 overflow-auto pb-32">
      <LoginScreen/>
      <img src={bg} className="w-screen h-screen object-cover fixed  top-0 left-0 opacity-20"/>
        <div className="flex flex-col gap-3 lg:max-h-[85vh] lg:flex-wrap">
        {!hasActiveScreen && <div className="h-full w-full flex items-center justify-center text-2xl text-white font-bold z-50">Where'd all my windows go?</div>}
          {safari.map((page, i) => {
            return <div key={`screen:${page.name}`} className={`${page.span} screen top-0 left-0 max-h-[90vh] h-auto`} style={{
              animationDelay: loading ? `${(100 * i + 1) + 3200}ms` : '0ms',
              position: page.fullScreen ? 'fixed' : 'static'
            }}><SafariScreen
              title={page.name}
              page={page}
              key={page.name}
              code={page.code}>
              {page.node}
            </SafariScreen></div>
          })}  
        </div>
        <Dock/>
    </div>
  );
}