import { SafariScreen } from "./SafariScreen"
//@ts-ignore
import resume from '../Documents/pc_resume.pdf';
import { useApp } from "../context/appContext";
import { pingDiscord } from "../util/discord";
//@ts-ignore
import profilePic from '../Documents/pfp.webp'

export const AboutMe = () => {

    const { user } = useApp();
    
    return <>
        <div className="flex flex-col gap-3">
            <div className="flex gap-3 items-center">
                <div className="w-24 h-24 relative">
                    <div className="w-0 h-0 
                    border-t-[50px] border-t-transparent
                    border-r-[100px] border-r-white/30
                    border-b-[50px] border-b-transparent
                    transform rotate-[40deg]
                    absolute -top-3 -left-4
                    "></div>
                    <img className="absolute top-2 left-0" src={profilePic}/>
                </div>
                <div>
                    <div className="font-semibold">Phil C.</div>
                    <div className="text-sm">Sr. Software Engineer</div>
                    <div className="italic text-xs text-stone-800/50">Front End Specialist</div>
                </div>
            </div>
            <div className="flex gap-2">
                <a onClick={() => pingDiscord({
                    title: `${user} clicked on instagram`
                })} target="_blank" href="https://www.instagram.com/treeki/" className="relative transition-all bg-purple-300/80 p-2 rounded text-white/80 cursor-pointer hover:bg-purple-400/100 transitision-all">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="25" height="25" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
                </a>
                <a onClick={() => pingDiscord({
                    title: `${user} clicked on linked in`
                })} target="_blank" href="https://www.linkedin.com/in/aisaka/" className="relative transition-all bg-blue-300/80 p-2 rounded text-white/80 cursor-pointer hover:bg-blue-400/100 transitision-all">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="25" height="25" viewBox="0 0 448 512"><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"/></svg>
                </a>
                <a onClick={() => pingDiscord({
                    title: `${user} clicked on github`
                })} target="_blank" href="https://github.com/MeowTaiga" className="relative transition-all bg-zinc-800/80 p-2 rounded text-white/80 cursor-pointer hover:bg-zinc-800/100 transitision-all">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="25" height="25" viewBox="0 0 496 512"><path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"/></svg>
                </a>
                <div onClick={() => pingDiscord({
                    title: `${user} clicked on email`
                })} className="relative transition-all bg-teal-300/80 p-2 rounded text-white/80 cursor-pointer hover:bg-teal-400/100 transitision-all">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="25" height="25" viewBox="0 0 576 512"><path d="M32 208V384c0 17.7 14.3 32 32 32H224c17.7 0 32-14.3 32-32V208c0-61.9-50.1-112-112-112S32 146.1 32 208zm256 0V384c0 11.7-3.1 22.6-8.6 32H512c17.7 0 32-14.3 32-32V208c0-61.9-50.1-112-112-112H234.5c32.6 26.4 53.5 66.8 53.5 112zM64 448c-35.3 0-64-28.7-64-64V208C0 128.5 64.5 64 144 64H432c79.5 0 144 64.5 144 144V384c0 35.3-28.7 64-64 64H224 64zm48-256h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm224 0h96 56c13.3 0 24 10.7 24 24v80c0 13.3-10.7 24-24 24H440c-13.3 0-24-10.7-24-24V224H336c-8.8 0-16-7.2-16-16s7.2-16 16-16zm112 96h32V224H448v64z"/></svg>
                </div>
                <a onClick={() => pingDiscord({
                    title: `${user} clicked on resume`
                })} target="_blank" href={resume} className="relative transition-all bg-rose-800/30 p-2 rounded text-white/80 cursor-pointer hover:bg-rose-800/50 transitision-all">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="25" height="25" viewBox="0 0 384 512"><path d="M0 64C0 28.65 28.65 0 64 0H220.1C232.8 0 245.1 5.057 254.1 14.06L369.9 129.9C378.9 138.9 384 151.2 384 163.9V448C384 483.3 355.3 512 320 512H64C28.65 512 0 483.3 0 448V64zM352 192H240C213.5 192 192 170.5 192 144V32H64C46.33 32 32 46.33 32 64V448C32 465.7 46.33 480 64 480H320C337.7 480 352 465.7 352 448V192zM347.3 152.6L231.4 36.69C229.4 34.62 226.8 33.18 224 32.48V144C224 152.8 231.2 160 240 160H351.5C350.8 157.2 349.4 154.6 347.3 152.6z"/></svg>
                </a>
            </div>
        </div>
    </>
}