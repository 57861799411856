export const wordleWords = [
    "Seize",
    "Serve",
    "Sharp",
    "Shelf",
    "Shine",
    "Slice",
    "Solid",
    "Space",
    "Stand",
    "Stone",
    "Eager",
    "Earth",
    "Ebony",
    "Edict",
    "Eight",
    "Elect",
    "Elite",
    "Entry",
    "Every",
    "Extra",
    "eager",
    "eagle",
    "eagre",
    "eared",
    "earls",
    "early",
    "habit",
    "hacek",
    "hacks",
    "hadal",
    "jabot",
    "jacal",
    "jacks",
    "jacky",
    "jaded",
    "jades",
    "maars",
    "macaw",
    "macer",
    "maces",
    "mache",
    "macho",
    "machs",
    "macks",
    "macle",
    "macos",
    "macro",
    "madam",
    "madly",
    "madre",
    "mafia",
    "mafic",
    "mages",
    "magic",
    "magma",
    "magot",
    "magus",
    "mahal",
    "mahoe",
    "mahua",
    "mahwa",
    "maids",
    "maile",
    "maill",
    "mails",
    "maims",
    "mains",
    "mairs",
    "maist",
    "maize",
    "major",
    "makai",
    "makar",
    "maker",
    "makes",
    "makis",
    "makna",
    "makos",
    "malar",
    "males",
    "malic",
    "naans",
    "nabes",
    "nabis",
    "nabob",
    "nacho",
    "nacre",
    "nadas",
    "nadir",
    "naffs",
    "naggy",
    "naiad",
    "naifs",
    "nails",
    "naira",
    "nairu",
    "naive",
    "naked",
    "nakfa",
    "naled",
    "named",
    "namer",
    "names",
    "nanas",
    "nancy",
    "nanny",
    "nanos",
    "napas",
    "napes",
    "nappa",
    "nappe",
    "narco",
    "narcs",
    "nards",
    "nares",
    "naris",
    "narks",
    "nasal",
    "nashi",
    "nasty",
    "natal",
    "natch",
    "nates",
    "natty",
    "naval",
    "navel",
    "oaken",
    "oakum",
    "oared",
    "oasal",
    "oases",
    "oasis",
    "oasts",
    "oaten",
    "oater",
    "oaths",
    "obeah",
    "obeli",
    "obese",
    "obeys",
    "obiit",
    "obits",
    "objet",
    "oboes",
    "obole",
    "oboli",
    "obols",
    "occur",
    "ocean",
    "ocher",
    "ochre",
    "ochry",
    "ocker",
    "ocrea",
    "octad",
    "octal",
    "octan",
    "octet",
    "oculi",
    "odahs",
    "odder",
    "oddly",
    "odeum",
    "odium",
    "odors",
    "odour",
    "odyle",
    "odyls",
    "oecus",
    "offal",
    "offed",
    "pacas",
    "paced",
    "pacer",
    "paces",
    "pacha",
    "packs",
    "pacts",
    "padas",
    "paddy",
    "padre",
    "padri",
    "paean",
    "paeon",
    "pagan",
    "paged",
    "pager",
    "pages",
    "pagne",
    "pahos",
    "pails",
    "pains",
    "paint",
    "pairs",
    "paisa",
    "paise",
    "palea",
    "paled",
    "paler",
    "pales",
    "palis",
    "palki",
    "palla",
    "palls",
    "pally",
    "palms",
    "palmy",
    "palpi",
    "palps",
    "palsa",
    "palsy",
    "pampa",
    "panda",
    "pandy",
    "paned",
    "panel",
    "raabs",
    "rabal",
    "rabat",
    "rabbi",
    "rabic",
    "rabid",
    "rabot",
    "raced",
    "racer",
    "races",
    "racks",
    "racon",
    "radar",
    "radii",
    "radio",
    "radix",
    "radon",
    "raffe",
    "raffs",
    "rafts",
    "ragas",
    "raged",
    "rages",
    "ragga",
    "raggy",
    "ragis",
    "ragus",
    "raias",
    "raids",
    "rails",
    "rains",
    "rainy",
    "raise",
    "raita",
    "rajah",
    "rajas",
    "rakah",
    "rakas",
    "raked",
    "rakee",
    "raker",
    "rakes",
    "rakis",
    "rakus",
    "rales",
    "tabby",
    "taber",
    "tabes",
    "tabid",
    "tabis",
    "tabla",
    "table",
    "taboo",
    "tabor",
    "tabus",
    "taces",
    "tacet",
    "tache",
    "tachs",
    "tacit",
    "tacks",
    "tacky",
    "tacos",
    "tacts",
    "taels",
    "taffy",
    "tafia",
    "tagma",
    "tagua",
    "tahrs",
    "taiga",
    "tails",
    "tains",
    "taken",
    "taker",
    "takes",
    "takin",
    "talas",
    "talcs",
    "taler",
    "tales",
    "talks",
    "talky",
    "tally",
    "talon",
    "taluk",
    "talus",
    "tamas",
    "tamed",
    "tamer",
    "wacke",
    "wacko",
    "wacks",
    "wacky",
    "waddy",
    "waded",
    "wader",
    "wades",
    "wadis",
    "wadna",
    "wafer",
    "waffs",
    "wafts",
    "waged",
    "wager",
    "wages",
    "wagon",
    "wahoo",
    "waifs",
    "wails",
    "wains",
    "waist",
    "waits",
    "waive",
    "wakas",
    "waked",
    "waken",
    "waker",
    "wakes",
    "waled",
    "wales",
    "walks",
    "walla",
    "walls",
    "wally",
    "waltz",
    "wames",
    "wamus",
    "wands",
    "waned",
    "wanes",
    "waney",
    "wanly",
    "wanna",
    "wants",
]