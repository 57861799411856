//@ts-ignore

import { mockComms } from '../mocks/mockComms';
import { CommissionCard } from '../components/VRC/CommissionCard';
import { VRCBase } from '../components/VRC/Base';
import { Link, useParams } from 'react-router-dom';
import { mockUsers } from '../mocks/mockUsers';

export const VRCComms = () => {

    const { id } = useParams();

    const thisComm = mockComms.find(comm => comm.id == parseInt(id || '0'));
    const commOwner = mockUsers.find(u => u.id == thisComm?.owner);
    const similarType = mockComms.filter(c => c.type == thisComm?.type);
    if(!thisComm || !commOwner) return <></>;

    return <VRCBase>
            <section className="w-full flex flex-col gap-4 overflow-auto pb-10">
                <div className="flex gap-3">
                    <img src={thisComm.img} className="max-w-[50%] rounded"/>
                    <div className="bg-zinc-900 rounded p-2 w-full flex flex-col gap-1">
                        <div>
                            <Link to={`/vrchat/user/${commOwner.name}`} className="flex gap-2 items-center font-bold">
                                <img src={commOwner.avatar} className="w-10 h-10 rounded-full"/>
                                <div>{commOwner.name}</div>
                            </Link>
                        </div>
                        <div className="text-2xl font-bold">{thisComm.title}</div>
                        <div className="text-zinc-300">{thisComm.desc}</div>
                    </div>
                </div>
                {thisComm.comments && <section className="flex flex-col gap-2">
                    <div className="text-lg font-bold">Comments</div>
                    {thisComm.comments.map(comment => {
                        let commentUser = mockUsers.find(u => u.id == comment.owner);
                        return <div className="w-full p-2 bg-zinc-900 rounded">
                            <Link to={`/vrchat/user/${commentUser?.name}`} className="flex gap-2 items-center font-bold">
                                <img src={commentUser?.avatar} className="w-5 h-5 rounded-full"/>
                                <div>{commentUser?.name}</div>
                            </Link>
                            <div>{comment.content}</div>
                        </div>
                    })}
                </section>}
                <section className="flex flex-col gap-3">
                    <div className="text-2xl font-bold">View similiar {thisComm.type}</div>
                    <div className="grid gap-3 lg:grid-cols-4">
                        {similarType.map(comm => <CommissionCard key={comm.id} comm={comm} />)}
                    </div>
                </section>
            </section>
    </VRCBase>
}