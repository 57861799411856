export interface User {
    id: number;
    name: string;
    avatar: string;
    bio: string;
}

export const mockUsers: User[] = [
    {
        id: 1,
        name: 'Taiga',
        avatar: 'https://media.tenor.com/9-DWv-DAYuQAAAAC/taiga-aisaka.gif',
        bio: 'Front End Engineer 💻'
    },
    {
        id: 2,
        name: 'Orels1',
        avatar: 'https://avatars.githubusercontent.com/u/3798928?v=4',
        bio: 'VRChat Web Engineer'
    },
    {
        id: 3,
        name: 'VRC_Animator',
        avatar: 'https://img.freepik.com/premium-vector/cute-fox-sitting-cartoon-character-animal-nature-isolated_138676-3172.jpg?w=2000',
        bio: 'I do animator commissions'
    },
    {
        id: 4,
        name: 'Meow',
        avatar: 'https://helloartsy.com/wp-content/uploads/kids/cats/how-to-draw-a-small-cat/how-to-draw-a-small-cat-step-6.jpg',
        bio: 'I do animator commissions'
    },
    {
        id:5, 
        name: 'ChibiAvatar',
        avatar: 'https://www.pngitem.com/pimgs/m/434-4349410_freetoedit-cute-kawaii-girl-anime-manga-chibi-fox.png',
        bio: 'I do chibi art for VRChat'
    }
]