export const codeTheme = {
    "code[class*=\"language-\"]": {
      "textAlign": "left",
      "whiteSpace": "pre",
      "wordSpacing": "normal",
      "wordBreak": "normal",
      "wordWrap": "normal",
      "color": "#eee",
      "fontFamily": "Roboto Mono, monospace",
      "fontSize": "1em",
      "lineHeight": "1.5em",
      "MozTabSize": "4",
      "OTabSize": "4",
      "tabSize": "4",
      "WebkitHyphens": "none",
      "MozHyphens": "none",
      "msHyphens": "none",
      "hyphens": "none"
    },
    "pre[class*=\"language-\"]": {
      "textAlign": "left",
      "whiteSpace": "pre",
      "wordSpacing": "normal",
      "wordBreak": "normal",
      "wordWrap": "normal",
      "color": "#eee",
      "fontFamily": "Roboto Mono, monospace",
      "fontSize": "1em",
      "lineHeight": "1.5em",
      "MozTabSize": "4",
      "OTabSize": "4",
      "tabSize": "4",
      "WebkitHyphens": "none",
      "MozHyphens": "none",
      "msHyphens": "none",
      "hyphens": "none",
      "overflow": "auto",
      "position": "relative",
      "margin": "0.5em 0",
    },
    "code[class*=\"language-\"]::-moz-selection": {
      
    },
    "pre[class*=\"language-\"]::-moz-selection": {
      
    },
    "code[class*=\"language-\"] ::-moz-selection": {
      
    },
    "pre[class*=\"language-\"] ::-moz-selection": {
      
    },
    "code[class*=\"language-\"]::selection": {
      
    },
    "pre[class*=\"language-\"]::selection": {
      
    },
    "code[class*=\"language-\"] ::selection": {
      
    },
    "pre[class*=\"language-\"] ::selection": {
      
    },
    ":not(pre) > code[class*=\"language-\"]": {
      "whiteSpace": "normal",
      "borderRadius": "0.2em",
      "padding": "0.1em"
    },
    ".language-css > code": {
      "color": "#fd9170"
    },
    ".language-sass > code": {
      "color": "#fd9170"
    },
    ".language-scss > code": {
      "color": "#fd9170"
    },
    "[class*=\"language-\"] .namespace": {
      "Opacity": "0.7"
    },
    "atrule": {
      "color": "#c792ea"
    },
    "attr-name": {
      "color": "#ffcb6b"
    },
    "attr-value": {
      "color": "#a5e844"
    },
    "attribute": {
      "color": "#a5e844"
    },
    "boolean": {
      "color": "#c792ea"
    },
    "builtin": {
      "color": "#ffcb6b"
    },
    "cdata": {
      "color": "#80cbc4"
    },
    "char": {
      "color": "#80cbc4"
    },
    "class": {
      "color": "#ffcb6b"
    },
    "class-name": {
      "color": "#f2ff00"
    },
    "comment": {
      "color": "#616161"
    },
    "constant": {
      "color": "#c792ea"
    },
    "deleted": {
      "color": "#ff6666"
    },
    "doctype": {
      "color": "#616161"
    },
    "entity": {
      "color": "#ff6666"
    },
    "function": {
      "color": "#c792ea"
    },
    "hexcode": {
      "color": "#f2ff00"
    },
    "id": {
      "color": "#c792ea",
      "fontWeight": "bold"
    },
    "important": {
      "color": "#c792ea",
      "fontWeight": "bold"
    },
    "inserted": {
      "color": "#80cbc4"
    },
    "keyword": {
      "color": "#c792ea"
    },
    "number": {
      "color": "#fd9170"
    },
    "operator": {
      "color": "#89ddff"
    },
    "prolog": {
      "color": "#616161"
    },
    "property": {
      "color": "#80cbc4"
    },
    "pseudo-class": {
      "color": "#a5e844"
    },
    "pseudo-element": {
      "color": "#a5e844"
    },
    "punctuation": {
      "color": "#89ddff"
    },
    "regex": {
      "color": "#f2ff00"
    },
    "selector": {
      "color": "#ff6666"
    },
    "string": {
      "color": "#a5e844"
    },
    "symbol": {
      "color": "#c792ea"
    },
    "tag": {
      "color": "#ff6666"
    },
    "unit": {
      "color": "#fd9170"
    },
    "url": {
      "color": "#ff6666"
    },
    "variable": {
      "color": "#ff6666"
    }
  };