
import ReactDOM from 'react-dom/client';
import { AppProvider } from './context/appContext';
import { RouterProvider } from "react-router-dom";
import { router } from './routes';
import './App.css';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <AppProvider>
    <RouterProvider router={router} />
  </AppProvider>
);

