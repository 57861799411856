//@ts-ignore
import VRCLogo from '../../Documents/vrc_logo.png'
import { useState, Suspense, useEffect } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { GenshiModel } from './GenshiModel'
import { Bounds, useBounds, OrbitControls } from '@react-three/drei'
import { Link, useLocation } from 'react-router-dom'
import { pingDiscord } from '../../util/discord'

export const VRCBase = ({children}: { children: React.ReactNode}) => {

    const [ commission, setCommission ] = useState(false);
    const location = useLocation();
    useEffect(() => {
        pingDiscord({
            title: 'Someone Visited VRChat Platform',
            content: location.pathname
        })
    }, [])


    return <div className="bg-zinc-800 text-white min-h-screen">
        <header className="p-4 w-full fixed top-0 left-0 bg-zinc-900 flex justify-between items-center z-10">
            <Link to={'/vrchat'} className="flex gap-2 justify-center items-center">
                <img src={VRCLogo} className="h-14"/>
            </Link>
            <div>
                <Link to={'/vrchat/user/Taiga'} className="flex gap-3 items-center">
                    <div className="font-bold">Taiga</div>
                    <img src="https://media.tenor.com/9-DWv-DAYuQAAAAC/taiga-aisaka.gif" className="w-10 h-10 object-contain rounded-full"/>
                </Link>
            </div>
        </header>

        <div className="flex gap-3 p-2 lg:px-10 w-full pt-24 lg:pt-28 lg:flex-row flex-col lg:max-h-[100vh]">
            <section className="hidden lg:w-[30%] lg:flex flex-col gap-3 sticky top-20">
                <div className="p-4 rounded bg-zinc-900">
                <div className="text-xl font-bold">Welcome back Taiga!</div>
                <div className="text-sm text-zinc-300 italic">Looks like you have no active commissions!</div>
                <div className="flex gap-3 mt-3">
                        <div className="rounded bg-zinc-700 text-center p-2 px-4 cursor-pointer hover:bg-blue-300/50 transition-all flex items-center justify-center gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-4 h-4" viewBox="0 0 576 512"><path d="M288 400C288 461.9 237.9 512 176 512H32C14.33 512 0 497.7 0 480C0 462.3 14.33 448 32 448H36.81C54.44 448 66.4 429.1 64.59 411.6C64.2 407.8 64 403.9 64 400C64 338.1 114.1 288 176 288C182.7 288 189.3 288.6 195.7 289.7L466.7 18.75C491.7-6.248 532.2-6.248 557.2 18.75C582.2 43.74 582.2 84.26 557.2 109.3L286.3 380.2C287.4 386.6 288 393.2 288 400L288 400zM534.5 86.63C547 74.13 547 53.87 534.5 41.37C522 28.88 501.8 28.88 489.3 41.37L324.6 206L369.9 251.3L534.5 86.63zM347.3 273.9L302 228.6L224.6 306L269.9 351.3L347.3 273.9zM96 400C96 402.8 96.14 405.6 96.43 408.3C98.15 425 93.42 441.9 83.96 455.1C74.31 468.5 58 480 36.81 480H176C220.2 480 256 444.2 256 400C256 393.1 255.1 386.5 253.5 380.1L195.9 322.5C189.5 320.9 182.9 320 176 320C131.8 320 96 355.8 96 400V400zM170.8 16.97L296.1 143.2L274.4 165.8L225.6 117L149 193.6L197.8 242.4L183.9 256.2C181.3 256.1 178.7 255.1 176 255.1C172.8 255.1 169.6 256.1 166.5 256.3L48.97 138.8C27.1 116.9 27.1 81.47 48.97 59.6L91.6 16.97C113.5-4.899 148.9-4.899 170.8 16.97V16.97zM71.6 82.23C62.23 91.6 62.23 106.8 71.6 116.2L126.4 170.1L202.1 94.4L148.2 39.6C138.8 30.23 123.6 30.23 114.2 39.6L71.6 82.23zM410.1 301.6L432.8 278.9L483.6 329.7C494.9 341.1 503.2 355.1 507.8 370.5L543.4 491.5C545 497.1 543.5 503.2 539.3 507.3C535.2 511.5 529.1 513 523.5 511.4L402.5 475.8C387.1 471.2 373.1 462.9 361.7 451.6L319.7 409.5C319.9 406.4 320 403.2 320 400C320 397.3 319.9 394.6 319.8 391.9L333.6 378.1L384.4 428.9C391.9 436.5 401.3 442.1 411.6 445.1L504.4 472.4L477.1 379.6C474.1 369.3 468.5 359.9 460.9 352.4L410.1 301.6z"/></svg>
                            <div className="hidden xl:block">Request</div>
                        </div>
                        <div className="rounded bg-zinc-700 text-center p-2 px-4 cursor-pointer hover:bg-blue-300/50 transition-all flex items-center justify-center gap-2" onClick={() => setCommission(!commission)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-4 h-4" viewBox="0 0 448 512"><path d="M64 0C28.7 0 0 28.7 0 64L0 448l0 0c0 35.3 28.7 64 64 64H432c8.8 0 16-7.2 16-16s-7.2-16-16-16H416V413.3c18.6-6.6 32-24.4 32-45.3V48c0-26.5-21.5-48-48-48H64zM384 416v64H64c-17.7 0-32-14.3-32-32s14.3-32 32-32H384zM64 384c-11.7 0-22.6 3.1-32 8.6L32 64c0-17.7 14.3-32 32-32H400c8.8 0 16 7.2 16 16V368c0 8.8-7.2 16-16 16H64zM265.9 170.9l-16.5 38.6-38.6 16.5c-19.4 8.3-19.4 35.8 0 44.1l38.6 16.5 16.5 38.6c8.3 19.4 35.8 19.4 44.1 0l16.5-38.6 38.6-16.5c19.4-8.3 19.4-35.8 0-44.1l-38.6-16.5-16.5-38.6c-8.3-19.4-35.8-19.4-44.1 0zM288 200.6l11.7 27.3c1.6 3.8 4.6 6.8 8.4 8.4L335.4 248l-27.3 11.7c-3.8 1.6-6.8 4.6-8.4 8.4L288 295.4l-11.7-27.3c-1.6-3.8-4.6-6.8-8.4-8.4L240.6 248l27.3-11.7c3.8-1.6 6.8-4.6 8.4-8.4L288 200.6zM153 121.8l7-14 7 14c1.5 3.1 4.1 5.6 7.2 7.2l14 7-14 7c-3.1 1.5-5.6 4.1-7.2 7.2l-7 14-7-14c-1.5-3.1-4.1-5.6-7.2-7.2l-14-7 14-7c3.1-1.5 5.6-4.1 7.2-7.2zm28.5-42.7c-8.8-17.7-34.1-17.7-42.9 0l-11.8 23.6-23.6 11.8c-17.7 8.8-17.7 34.1 0 42.9l23.6 11.8 11.8 23.6c8.8 17.7 34.1 17.7 42.9 0l11.8-23.6 23.6-11.8c17.7-8.8 17.7-34.1 0-42.9l-23.6-11.8L181.5 79.2z"/></svg>
                            <div className="hidden xl:block">{commission ? 'Close' : 'Open'} Commissions</div>
                        </div>
                </div>
                </div>
                <div className="rounded py-2 font-bold text-xl transition-all">
                    ⭐ Featured Model
                </div>
                <div className="rounded bg-zinc-900">
                    <div className="flex flex-col gap-1">
                        <div className="h-[40vh] relative">
                            <Canvas camera={{ zoom: 2.5 }} dpr={[1, 2]}>
                                <Suspense fallback={null}>
                                    <GenshiModel scale={5} position={[0, -5, 0]}/>
                                </Suspense>
                                <OrbitControls makeDefault minPolarAngle={0} maxPolarAngle={Math.PI / 1.75} />
                            </Canvas>
                            <div className="absolute bottom-0 left-0 flex justify-between w-full p-2">
                                <Link to={`/vrchat`} className="flex items-center gap-2 hover:text-purple-300 transition-all w-auto">
                                    <img src={'https://preview.redd.it/o8smnmp6erp21.png?width=486&format=png&auto=webp&s=c12d21cff57dd954616fc7e0e60cfb52711e8089'} className="w-6 h-6 object-contain rounded-full"/>
                                    VRChat
                                </Link>
                                <div className="bg-green-300 rounded px-3 py-1 opacity-90 font-bold text-zinc-900">$29.99</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
                {children}
            </div>
        </div>
}