import { Commission } from "../../mocks/mockComms";
import { useState } from 'react';
import { Link } from "react-router-dom";
import { mockUsers } from "../../mocks/mockUsers";
export const CommissionCard = ({comm}: {comm: Commission}) => {

    const [ showDesc, setShowDesc ] = useState(false);
    const [ likeCount, setLikeCount ] = useState(comm.likes);
    const isLiked = likeCount !== comm.likes;

    const handleLike = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        !isLiked ? setLikeCount(c => c+1) : setLikeCount(c => c-1)
    }

    const owner = mockUsers.find(e => e.id == comm.owner)
    if(!owner) return <></>;
    return <Link to={`/vrchat/comms/${comm.id}`} className="rounded bg-stone-900 relative cursor-pointer transition-all" 
        onMouseEnter={() => setShowDesc(true)}
        onMouseLeave={() => setShowDesc(false)}>
            {!!comm.img && <img src={comm.img} className="object-cover rounded w-full h-[25vh]"/>}
            <div className="rounded px-3 py-1 bg-zinc-900 absolute top-2 right-2 text-xs shadow">{comm.type}</div>
            <div className="p-4 flex flex-col gap-1 img-overlay justify-end rounded">
                <div className="flex flex-col gap-1">
                    <div className="flex justify-between">
                        <Link to={`/vrchat/user/${owner.name}`} className="flex items-center gap-2 hover:text-purple-300 transition-all w-auto">
                            <img src={owner.avatar} className="w-6 h-6 object-contain rounded-full"/>
                            {owner.name}
                        </Link>
                    </div>
                    <div className="text-xl font-bold">{comm.title}</div>
                    {(showDesc && comm.desc) && <div className="text-xs italic text-zinc-300">{comm.desc}</div>}
                </div>
                <div className="flex justify-between items-center">
                    <div className={`flex items-center gap-1 hover:text-purple-300 transition-all ${isLiked ? 'text-purple-300' : 'text-white'}`} onClick={handleLike}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-4 h-4" viewBox="0 0 512 512"><path d="M288.8 81.7c3.5-12.8 16.7-20.3 29.5-16.8s20.3 16.7 16.8 29.5l-4.5 16.4c-5.5 20.2-13.9 39.3-24.7 56.9c-3.1 4.9-3.2 11.1-.4 16.2s8.2 8.2 14 8.2H448c17.7 0 32 14.3 32 32c0 11.3-5.9 21.3-14.8 27c-7.2 4.6-9.5 13.9-5.3 21.3c2.6 4.6 4.1 10 4.1 15.7c0 12.4-7 23.1-17.3 28.5c-4.2 2.2-7.3 6.1-8.3 10.8s.1 9.5 3 13.2c4.2 5.4 6.7 12.2 6.7 19.5c0 14.2-9.2 26.3-22.1 30.4c-7.8 2.6-12.4 10.6-10.7 18.6c.5 2.2 .7 4.5 .7 6.9c0 17.7-14.3 32-32 32H294.5c-15.8 0-31.2-4.7-44.4-13.4l-38.5-25.7c-9-6-16.6-13.7-22.4-22.6c-4.9-7.4-14.8-9.4-22.2-4.6s-9.4 14.8-4.6 22.2c8.1 12.3 18.7 23.1 31.4 31.6l38.5 25.7c18.4 12.3 40 18.8 62.1 18.8H384c35.3 0 64-28.7 64-64l0-.6c19.1-11.1 32-31.7 32-55.4c0-8.7-1.8-17.1-4.9-24.7C487.9 323.6 496 306.8 496 288c0-6.5-1-12.8-2.8-18.7C504.8 257.7 512 241.7 512 224c0-35.3-28.7-64-64-64H346.4c6.2-13.1 11.3-26.7 15.1-40.9l4.5-16.4c8.1-29.8-9.5-60.6-39.3-68.8s-60.6 9.5-68.8 39.3l-4.5 16.4c-8.9 32.6-29.6 60.8-58.2 79l-3.1 2c-11.8 7.5-21.7 17.1-29.5 28.2c-5.1 7.2-3.3 17.2 4 22.3s17.2 3.3 22.3-4c5.4-7.7 12.2-14.4 20.4-19.5l3.1-2c35.3-22.4 60.9-57.2 71.9-97.5l4.5-16.4zM32 224H96V448H32V224zM0 224V448c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32H32c-17.7 0-32 14.3-32 32z"/></svg>
                        {likeCount}
                    </div>
                    {!!comm.comments && <div className="flex items-center gap-1 hover:text-purple-300 transition-all">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" fill="currentColor" className="w-4 h-4"><path d="M416 176C416 78.8 322.9 0 208 0S0 78.8 0 176c0 41.48 17.07 79.54 45.44 109.6c-15.17 32.34-38.65 58.07-38.95 58.38c-6.514 6.836-8.309 16.91-4.568 25.67C5.754 378.4 14.26 384 23.66 384c54.19 0 97.76-20.73 125.9-39.17C168.1 349.4 187.7 352 208 352C322.9 352 416 273.2 416 176zM208 320c-16.96 0-34.04-2.098-50.75-6.232L143.7 310.4L132 318.1c-20.43 13.38-51.58 28.99-89.85 32.97c9.377-12.11 22.3-30.63 32.24-51.82l9.242-19.71L68.72 263.7C44.7 238.2 32 207.9 32 176C32 96.6 110.1 32 208 32S384 96.6 384 176S305 320 208 320zM606.4 435.4C627.6 407.1 640 372.9 640 336C640 238.8 554 160 448 160c-.3145 0-.6191 .041-.9336 .043C447.5 165.3 448 170.6 448 176c0 5.43-.4668 10.76-.9414 16.09C447.4 192.1 447.7 192 448 192c88.22 0 160 64.6 160 144c0 28.69-9.424 56.45-27.25 80.26l-13.08 17.47l11.49 18.55c6.568 10.61 13.18 19.74 18.61 26.74c-18.26-1.91-36.45-6.625-54.3-14.09l-12.69-5.305l-12.58 5.557C495.9 475 472.3 480 448 480c-75.05 0-137.7-46.91-154.9-109.7c-10.1 3.336-20.5 6.132-31.2 8.271C282.7 455.1 357.1 512 448 512c29.82 0 57.94-6.414 83.12-17.54C555 504.5 583.7 512 616.3 512c9.398 0 17.91-5.57 21.73-14.32c3.74-8.758 1.945-18.84-4.568-25.67C633.3 471.8 619.6 456.8 606.4 435.4z"/></svg>
                        {comm.comments.length}
                    </div>}
                </div>
            </div>
        </Link>
}