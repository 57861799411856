import { notEqual } from "assert";
import { useState } from "react"
import { Pages, SafariPage, useApp } from "../context/appContext"
import { pingDiscord } from "../util/discord";

interface SafariScreen {
    children: React.ReactNode;
    title: string;
    page: SafariPage;
    code?: boolean;
}


export const SafariScreen: React.FC<SafariScreen> = ({children, title, page, code}) => {

    const { safariPreserve, toggleSafariPage, fullScreenSafariPage } = useApp();
    const customPage = [Pages.TICTACTOE, Pages.GAMES]
    const isCustomPage  = customPage.includes(page.name);
    const { user } = useApp();

    if(isCustomPage) return <div onClick={() => pingDiscord({
        title: `${user} clicked on ${page.name}`,
    })}
    className={`overflow-auto relative flex flex-col gap-3 origin-bottom-right ${page.fullScreen && 'm-[1vw] block z-50'}`} 
    style={{
        position: page.fullScreen ? 'fixed' : 'relative', 
        opacity: page.active ? 1 : 0,
        transform: page.active ? 'translateY(0) scale(1) translateX(0)' : 'translateY(100vh) scale(0) translatex(100vw)',
        transition: 'all 0.4s ease-out',
        width: page.fullScreen ? '98vw' : '100%',
        height: page.fullScreen ? '80vh' : 'fit-content',
        top: 0,
        left: 0,
    }}>{children}</div>

    return <div onClick={() => pingDiscord({
        title: `${user} clicked on ${page.name}`,
    })} className={`${code ? 'bg-stone-800/30 text-white' : 'bg-white/30'} overflow-auto backdrop-blur-md bg-white/30 rounded p-4 pt-6 relative flex flex-col gap-3 origin-bottom-right ${page.fullScreen && 'm-[1vw] block z-50'}`} 
    style={{
        position: page.fullScreen ? 'fixed' : 'relative', 
        opacity: page.active ? 1 : 0,
        transform: page.active ? 'translateY(0) scale(1) translateX(0)' : 'translateY(100vh) scale(0) translatex(100vw)',
        transition: 'all 0.4s ease-out',
        width: page.fullScreen ? '98vw' : '100%',
        height: page.fullScreen ? '80vh' : 'fit-content',
        top: 0,
        left: 0,
    }}>
        <div className={`${code ? 'bg-stone-900/30' : 'bg-white/30'} absolute top-0 left-0 rounded-t p-2 w-full flex justify-between items-center ${page.span}`}>
            {page.fullScreen ? <div className="flex">
                {safariPreserve.map(e => {
                    return <div onClick={() => fullScreenSafariPage(e)} className={`${e.name == page.name && 'text-white/80 bg-stone-700/30 rounded'} border-r-2 border-white/30 px-4 cursor-pointer`}>{e.name}</div>
                })}
            </div> : <div className="font-bold">{title}</div>}
            <div className="flex gap-2">
                <div className={`rounded-full ${page.fullScreen ? 'bg-yellow-300 p-2 hover:bg-yellow-300/50' : 'bg-green-300 p-2 hover:bg-green-300/50'} cursor-pointer transition-all`}
                    onClick={() => fullScreenSafariPage(page)}></div>
                <div className={`rounded-full ${page.active ? 'bg-red-300 p-2 hover:bg-red-300/50' : 'bg-green-300 p-2 hover:bg-green-300/50'} cursor-pointer transition-all`}
                    onClick={() => toggleSafariPage(page)}></div>
            </div>
        </div>
        <div className="mt-6">
            {children}  
        </div>
    </div>
}