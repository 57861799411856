/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: fangzhangmnm (https://sketchfab.com/fangzhangmnm)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/klee-genshin-impact-version-2-5e95df75395c4fbab25bfc2ead82d7e0
title: Klee Genshin Impact version 2
*/

import React, { useEffect, useRef } from "react";
//@ts-ignore
import { useGLTF, useAnimations } from "@react-three/drei";
//@ts-ignore
import Genshi from '../../Documents/genshi.glb'

export const GenshiModel = (props: any) => {
  const group = useRef();
  //@ts-ignore
  const { nodes, materials, animations } = useGLTF(Genshi);
  const {actions} = useAnimations(animations, group);

  useEffect(() => {
    //@ts-ignore
    actions?.Throw.play()
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Sketchfab_Scene">
        <group name="Sketchfab_model" rotation={[-Math.PI / 2, 0, 0]}>
          <group name="root">
            <group name="GLTF_SceneRootNode" rotation={[Math.PI / 2, 0, 0]}>
              <group name="Armature_101">
                <group name="GLTF_created_0">
                  <primitive object={nodes.GLTF_created_0_rootJoint} />
                  <group name="Bag_97" />
                  <group name="Costume_98" />
                  <group name="Face_99" />
                  <group name="Hair_100" />
                  <skinnedMesh
                    name="Object_10"
                    geometry={nodes.Object_10.geometry}
                    material={materials.OH_Outline_Material}
                    skeleton={nodes.Object_10.skeleton}
                  />
                  <skinnedMesh
                    name="Object_15"
                    geometry={nodes.Object_15.geometry}
                    material={materials.OH_Outline_Material}
                    skeleton={nodes.Object_15.skeleton}
                  />
                  <skinnedMesh
                    name="Object_7"
                    geometry={nodes.Object_7.geometry}
                    material={materials.material}
                    skeleton={nodes.Object_7.skeleton}
                  />
                  <skinnedMesh
                    name="Object_12"
                    geometry={nodes.Object_12.geometry}
                    material={materials.Face}
                    skeleton={nodes.Object_12.skeleton}
                  />
                  <skinnedMesh
                    name="Object_14"
                    geometry={nodes.Object_14.geometry}
                    material={materials.Hair}
                    skeleton={nodes.Object_14.skeleton}
                  />
                  <skinnedMesh
                    name="Object_9"
                    geometry={nodes.Object_9.geometry}
                    material={materials.Costume}
                    skeleton={nodes.Object_9.skeleton}
                  />
                </group>
              </group>
            </group>
          </group>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload(Genshi);