import { useApp } from "../context/appContext"
import { useState, useRef } from 'react'
import { HackerText } from "./HackerText";
import { pingDiscord } from "../util/discord";
//@ts-ignore
import bg from '../Documents/login-bg.jpg'
//@ts-ignore
import profilePic from '../Documents/pfp.webp'

export const LoginScreen = () => {

    const { user, updateUser } = useApp();
    const [ input, setInput ] = useState('');
    
    const saveUser = () => {
        pingDiscord({
            title: `${input || 'Anonymous'} Signed into Aisaka.dev`,
        })
        if(!input) return updateUser('Anonymous');
        updateUser(input)
    }

    return <div className="fixed shadow w-screen h-screen Pastel bg-gradient-to-tr from-violet-500 to-orange-300 z-50 flex items-center justify-center flex-col gap-3"
        style={{ 
            top: 0,
            left: 0,
            transform: !!user ? 'translateY(-100vh)' : 'translateY(0)',
            transition: 'all 0.4s ease-in',
            transitionDelay: '2s'
        }}
    >
        <img src={bg} className="w-screen h-screen object-cover fixed  top-0 left-0 opacity-20"/>
        <div className="flex flex-col gap-3 items-center justify-center p-4 pt-6 backdrop-blur-md bg-white/30 rounded shadow-sm min-w-[20vw]">
            <div className="w-36 h-36 relative">
                <div className={`w-36 h-36 rounded-full bg-white/30 ${user && 'rotating'} flex items-center justify-center`}>
                <img className="w-24" src={profilePic}/>
                </div>
            </div>
            {user ? <div className="text-white font-semibold text-xl"><HackerText text={`Welcome to my Portfolio ${user}!`}/></div> : 
            <>
                <input onKeyDown={e => e.key == 'Enter' && saveUser()} onChange={e => setInput(e.target.value)} type="text" placeholder="Whos visiting?" className=" w-full mt-2 p-2 rounded bg-white/30 text-white focus:outline-none placeholder:text-stone-900/60"/>
                <div className="flex gap-2 w-full">
                <div onClick={saveUser} className="transition-all rouned bg-green-300/50 text-center text-white p-2 rounded cursor-pointer w-full hover:bg-green-600/30 hover:-translate-y-1">Enter</div>
                <div onClick={saveUser} className="transition-all rouned bg-blue-500/30 text-center text-white p-2 px-4 rounded cursor-pointer w-auto hover:bg-blue-600/30 hover:-translate-y-1">Skip</div>
                </div>
            </>}
        </div>
    </div>
}