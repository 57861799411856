import { useState } from "react";
import { SafariPage, useApp } from "../context/appContext"
import { ToolTip } from "./Tooltip";

enum DockItems {
    safari = 'SAFARI',
    notes = 'NOTES',
    chat = 'CHAT',
    code = 'CODE',
    game = 'GAME',
}


export const Dock = () => {
    
    const [ hover, setHover ] = useState<DockItems | null>(null)
    const { safari, toggleSafariPage, user } = useApp();
    const codePage = safari.find(p => p.name == 'VS Code');
    const commentsPage = safari.find(p => p.name == 'Comments');
    const gamePage = safari.find(p => p.name == 'Games');

    if(!codePage || !commentsPage || !gamePage) return <></>;

    const DockApps = [
        {
            id: DockItems.safari,
            icon: <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="35" height="35" viewBox="0 0 512 512"><path d="M232 256C232 242.7 242.7 232 256 232C269.3 232 280 242.7 280 256C280 269.3 269.3 280 256 280C242.7 280 232 269.3 232 256zM348.6 122.9C373.6 114.6 397.4 138.4 389.1 163.4L340.2 309.9C335.5 324.2 324.2 335.5 309.9 340.2L163.4 389.1C138.4 397.4 114.6 373.6 122.9 348.6L171.8 202.1C176.5 187.8 187.8 176.5 202.1 171.8L348.6 122.9zM358.7 153.3L212.2 202.1C207.5 203.7 203.7 207.5 202.1 212.2L153.3 358.7L299.8 309.9C304.5 308.3 308.3 304.5 309.9 299.8L358.7 153.3zM0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM256 480C379.7 480 480 379.7 480 256C480 132.3 379.7 32 256 32C132.3 32 32 132.3 32 256C32 379.7 132.3 480 256 480z"/></svg>,
            tooltip: <SafariHover show={hover === DockItems.safari}/>,
            bg: 'bg-blue-300/50',
        },
        {
            id: DockItems.notes,
            icon: <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="35" height="35" viewBox="0 0 512 512"><path d="M128 96c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32V256H368c-26.5 0-48 21.5-48 48v80H160c-17.7 0-32-14.3-32-32V96zM446.2 288c-1.6 4.5-4.2 8.7-7.6 12.1l-74.5 74.5c-3.4 3.4-7.6 6-12.1 7.6V304c0-8.8 7.2-16 16-16h78.2zM96 96V352c0 35.3 28.7 64 64 64H341.5c17 0 33.3-6.7 45.3-18.7l74.5-74.5c12-12 18.7-28.3 18.7-45.3V96c0-35.3-28.7-64-64-64H160c-35.3 0-64 28.7-64 64zM320 496c0-8.8-7.2-16-16-16H128c-53 0-96-43-96-96l0-240c0-8.8-7.2-16-16-16s-16 7.2-16 16V384c0 70.7 57.3 128 128 128H304c8.8 0 16-7.2 16-16z"/></svg>,
            tooltip: null,
            bg: 'bg-purple-300/50',
        },
        {
            id: DockItems.chat,
            icon: <svg onClick={() => toggleSafariPage(commentsPage)} xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="35" height="35" viewBox="0 0 640 512"><path d="M416 176C416 78.8 322.9 0 208 0S0 78.8 0 176c0 41.48 17.07 79.54 45.44 109.6c-15.17 32.34-38.65 58.07-38.95 58.38c-6.514 6.836-8.309 16.91-4.568 25.67C5.754 378.4 14.26 384 23.66 384c54.19 0 97.76-20.73 125.9-39.17C168.1 349.4 187.7 352 208 352C322.9 352 416 273.2 416 176zM208 320c-16.96 0-34.04-2.098-50.75-6.232L143.7 310.4L132 318.1c-20.43 13.38-51.58 28.99-89.85 32.97c9.377-12.11 22.3-30.63 32.24-51.82l9.242-19.71L68.72 263.7C44.7 238.2 32 207.9 32 176C32 96.6 110.1 32 208 32S384 96.6 384 176S305 320 208 320zM606.4 435.4C627.6 407.1 640 372.9 640 336C640 238.8 554 160 448 160c-.3145 0-.6191 .041-.9336 .043C447.5 165.3 448 170.6 448 176c0 5.43-.4668 10.76-.9414 16.09C447.4 192.1 447.7 192 448 192c88.22 0 160 64.6 160 144c0 28.69-9.424 56.45-27.25 80.26l-13.08 17.47l11.49 18.55c6.568 10.61 13.18 19.74 18.61 26.74c-18.26-1.91-36.45-6.625-54.3-14.09l-12.69-5.305l-12.58 5.557C495.9 475 472.3 480 448 480c-75.05 0-137.7-46.91-154.9-109.7c-10.1 3.336-20.5 6.132-31.2 8.271C282.7 455.1 357.1 512 448 512c29.82 0 57.94-6.414 83.12-17.54C555 504.5 583.7 512 616.3 512c9.398 0 17.91-5.57 21.73-14.32c3.74-8.758 1.945-18.84-4.568-25.67C633.3 471.8 619.6 456.8 606.4 435.4z"/></svg>,
            tooltip: <CommentsHover show={hover === DockItems.chat} page={commentsPage}/>,
            bg: 'bg-green-300/50',
        },
        {
            id: DockItems.code,
            icon: <svg onClick={() => toggleSafariPage(codePage)} xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="35" height="35" viewBox="0 0 640 512"><path d="M415.2 21.06L255.2 501.1C252.4 509.4 243.3 513.1 234.9 511.2C226.6 508.4 222 499.3 224.8 490.9L384.8 10.94C387.6 2.554 396.7-1.977 405.1 .8175C413.4 3.612 417.1 12.67 415.2 21.06L415.2 21.06zM170.6 139.9L40.08 256L170.6 372C177.2 377.9 177.8 388 171.1 394.6C166.1 401.2 155.1 401.8 149.4 395.9L5.371 267.1C1.955 264.9 .0003 260.6 0 256C-.0003 251.4 1.954 247.1 5.37 244L149.4 116C155.1 110.2 166.1 110.8 171.1 117.4C177.8 123.1 177.2 134.1 170.6 139.9L170.6 139.9zM490.6 116L634.6 244C638 247.1 640 251.4 640 255.1C640 260.6 638 264.9 634.6 267.1L490.6 395.9C484 401.8 473.9 401.2 468 394.6C462.2 388 462.8 377.9 469.4 372L599.9 255.1L469.4 139.9C462.8 134.1 462.2 123.1 468 117.4C473.9 110.8 484 110.2 490.6 116H490.6z"/></svg>,
            tooltip: <CodeHover show={hover === DockItems.code} page={codePage}/>,
            bg: 'bg-stone-700/50',
        },
        {
            id: DockItems.game,
            icon: <svg onClick={() => toggleSafariPage(gamePage)} xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="35" height="35" viewBox="0 0 384 512"><path d="M232 384c13.25 0 24-10.75 24-24s-10.75-24-24-24s-24 10.75-24 24S218.8 384 232 384zM296 336c13.25 0 24-10.75 24-24s-10.75-24-24-24s-24 10.75-24 24S282.8 336 296 336zM320 0H64C28.8 0 0 28.8 0 64l.0001 352c0 52.8 43.2 96 96 96h192c53.02 0 96-42.98 96-96L384 63.1C384 28.8 355.2 0 320 0zM352 416c0 35.35-28.65 64-64 64H96c-35.35 0-64-28.65-64-64V224h320V416zM352 192H32V64c0-17.67 14.33-32 32-32h256c17.67 0 32 14.33 32 32V192zM80 352h15.1l0 16C96 376.8 103.2 384 112 384s15.1-7.201 15.1-16V352h16c8.801 0 16-7.201 16-16c0-8.801-7.199-16-16-16h-16V304c0-8.801-7.199-16-15.1-16S96 295.2 96 303.1L96 320H80c-8.801 0-15.1 7.199-15.1 16C64 344.8 71.2 352 80 352z"/></svg>,
            tooltip: <GamesHover show={hover === DockItems.game} page={gamePage}/>,
            bg: 'bg-rose-700/30',
        },
    ]

    if(!user) return <></>;
    return <div className="fixed bottom-0 left-0 flex items-end justify-center w-screen lg:h-[15vh] dock" onMouseLeave={() => setHover(null)}>
        <div className="m-3 rounded p-2 bg-white/30 flex gap-2 backdrop-blur-md">
            {DockApps.map((item, i) => {
                return <div className={`${item.bg} relative rounded-full full p-2 text-white cursor-pointer !transition-all hover:!-translate-y-1 show-dock`}
                style={{ animationDelay: `${(100 * i + 1) + 2200}ms`}}
                onMouseEnter={() => setHover(item.id)}>
                {item.tooltip && item.tooltip}
                {item.icon}
            </div>
            })}
        </div>
    </div>
}

const SafariHover = ({show}: { show: boolean}) => {
    const { safari, toggleSafariPage } = useApp();

    return <ToolTip show={show}>
        <div className="flex gap-3">
            {safari.map((page) => {
                if(page.code || page.name == 'Comments' || page.name == 'Games') return;
                return <div key={`dock:${page.name}`} className="show-dock rounded bg-white/20 p-1 h-content inline relative w-[150px] fadein" onClick={() => toggleSafariPage(page)}>
                    <div className="bg-white/20 rounded-t w-full absolute top-0 left-0 px-2">{page.name}</div>
                    <div className="flex gap-1 flex-col">
                        <div className="flex gap-2 mt-6">
                            <div className="rounded h-2 w-full bg-blue-200/20"></div>
                            <div className="rounded h-2 bg-gray-200/20 w-[33%]"></div>
                        </div>
                        <div className="rounded h-2 w-full bg-yellow-200/20"></div>
                        <div className="flex gap-2 mt-6">
                            <div className="rounded h-2 bg-red-200/20 w-[33%]"></div>
                            <div className="rounded h-2 w-full bg-gray-200/20"></div>
                        </div>
                    </div>
                </div>
            })}
        </div>
    </ToolTip>
}

const CodeHover = ({show, page}: { show: boolean, page: SafariPage | undefined}) => {
    const { toggleSafariPage } = useApp();

    return <ToolTip show={show}>
    <div className="flex gap-3">
        <div className="rounded bg-stone-800/20 p-1 h-content inline relative w-[150px] show-dock" onClick={() => page && toggleSafariPage(page)}>
            <div className="bg-stone-800/20 rounded-t w-full absolute top-0 left-0 px-2">VS Code</div>
            <div className="flex gap-1 flex-col">
                <div className="flex gap-2 mt-6">
                    <div className="rounded h-2 w-full bg-blue-200/20"></div>
                    <div className="rounded h-2 bg-gray-200/20 w-[33%]"></div>
                </div>
                <div className="rounded h-2 w-full bg-yellow-200/20"></div>
                <div className="flex gap-2 mt-6">
                    <div className="rounded h-2 bg-red-200/20 w-[33%]"></div>
                    <div className="rounded h-2 w-full bg-gray-200/20"></div>
                </div>
            </div>
        </div>
    </div>
</ToolTip>
}

const CommentsHover = ({show, page}: { show: boolean, page: SafariPage | undefined}) => {
    const { toggleSafariPage } = useApp();

    return <ToolTip show={show}>
    <div className="flex gap-3">
        <div className="rounded bg-white/20 p-1 h-content inline relative w-[150px] show-dock" onClick={() => page && toggleSafariPage(page)}>
            <div className="bg-white/20 rounded-t w-full absolute top-0 left-0 px-2">Comments</div>
            <div className="flex gap-1 flex-col">
                <div className="flex gap-2 mt-6">
                    <div className="rounded w-full bg-blue-500/30 p-1 flex flex-col gap-1">
                        <div className="rounded h-2 bg-white/20 w-[33%]"></div>
                        <div className="rounded h-3 bg-white/20 w-[75%]"></div>
                    </div>
                    <div className="rounded h-2 opacity-0 w-[33%]"></div>
                </div>
                <div className="flex gap-2 items-end justify-end">
                    <div className="rounded w-[80%] bg-green-500/30 p-1 flex flex-col gap-1 justify-end items-end">
                        <div className="rounded h-2 bg-white/20 w-[100%] justify-self-end"></div>
                        <div className="rounded h-1 bg-white/20 w-[75%]"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ToolTip>
}

const GamesHover = ({show, page}: { show: boolean, page: SafariPage | undefined}) => {
    const { toggleSafariPage } = useApp();

    return <ToolTip show={show}>
    <div className="flex gap-3">
        <div className="rounded bg-blue-300/20 p-1 h-content inline relative w-[150px] show-dock" onClick={() => page && toggleSafariPage(page)}>
            <div className="bg-blue-300/20 rounded-t w-full absolute top-0 left-0 px-2">Wordle</div>
            <div className="flex flex-col gap-1 pt-5 items-center justify-center">
                <div className="flex gap-1">
                    <div className="bg-zinc-300/50 w-2 h-2"></div>
                    <div className="bg-zinc-300/50 w-2 h-2"></div>
                    <div className="bg-zinc-300/50 w-2 h-2"></div>
                    <div className="bg-zinc-300/50 w-2 h-2"></div>
                    <div className="bg-zinc-300/50 w-2 h-2"></div>
                </div>
                <div className="flex gap-1">
                    <div className="bg-zinc-300/50 w-2 h-2"></div>
                    <div className="bg-zinc-300/50 w-2 h-2"></div>
                    <div className="bg-zinc-300/50 w-2 h-2"></div>
                    <div className="bg-zinc-300/50 w-2 h-2"></div>
                    <div className="bg-zinc-300/50 w-2 h-2"></div>
                </div>
                <div className="flex gap-1">
                    <div className="bg-zinc-300/50 w-2 h-2"></div>
                    <div className="bg-zinc-300/50 w-2 h-2"></div>
                    <div className="bg-zinc-300/50 w-2 h-2"></div>
                    <div className="bg-zinc-300/50 w-2 h-2"></div>
                    <div className="bg-zinc-300/50 w-2 h-2"></div>
                </div>
                <div className="flex gap-1">
                    <div className="bg-zinc-300/50 w-2 h-2"></div>
                    <div className="bg-zinc-300/50 w-2 h-2"></div>
                    <div className="bg-zinc-300/50 w-2 h-2"></div>
                    <div className="bg-zinc-300/50 w-2 h-2"></div>
                    <div className="bg-zinc-300/50 w-2 h-2"></div>
                </div>
            </div>
        </div>
    </div>
</ToolTip>
}
