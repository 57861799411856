import {useState, useMemo} from 'react';
import { useApp } from '../context/appContext';
import { pingDiscord } from '../util/discord';
import { wordleWords } from '../util/words';

export const Games = () => {

    return <div className="flex gap-3 xl:flex-row flex-col">
        <div className="bg-blue-300/40 rounded p-2 w-full backdrop-blur-md h-fit">
            <div className="text-xl font-bold text-white">Play Wordle!</div>
            <div className="text-xs italic text-zinc-100 pb-3">
                Guess the word within five tries!
            </div>
            <Wordle/>
        </div>
        <div className="bg-yellow-300/40 rounded p-2 w-full backdrop-blur-md h-fit flex items-center justify-center text-2xl text-white font-bold">
            This Section is under construction!
        </div>
    </div>
}

interface BoardSpace {
    letter: string;
    correct: boolean;
    hasLetterInDifferentSpot: boolean;
}

const Wordle = () => {

    const [ guess, setGuess ] = useState('');
    const [ board, setBoard ] = useState<any>(new Array(5).fill([]));
    const [ error, setError ] = useState('');
    const { user } = useApp()
    const correctWord = useMemo(() => wordleWords[Math.floor(Math.random() * wordleWords.length)], []);

    const submitGuess = () => {
        pingDiscord({
            title: `${user} tried to guess in wordle!`,
            content: `they guessed ${guess}`
        })

        if(guess.length !== 5) return setError('Guesses must be 5 characters!');
        let newBoard = board;
        let currentRow = 0;
        newBoard.forEach((row: Array<BoardSpace>, i: number) => {
            if(currentRow !== 0) return;
            if(!row[0]) {
                let thisRow: Array<BoardSpace> = [];
                currentRow = i + 1;
                guess.split("").forEach((letter, i) => {

                    let boardSpace = {
                        letter: letter,
                        correct: false,
                        hasLetterInDifferentSpot: false
                    }

                    if(letter.toLowerCase() == correctWord[i].toLowerCase()) return thisRow.push({...boardSpace, correct: true})
                    if(correctWord.toLowerCase().includes(letter.toLowerCase())) return thisRow.push({...boardSpace, hasLetterInDifferentSpot: true})
                    return thisRow.push(boardSpace);
                })
                newBoard[i] = thisRow;
            }
        })
        setBoard(newBoard);
        setGuess('');
    }

    const updateGuess = (value: string) => {
        setError('');
        setGuess(value)
    }

    const restartGame = () => {
        setBoard(new Array(5).fill([]))
    }

    return <div className="flex gap-3 flex-col">
        {board.map((row: Array<BoardSpace>) => {
            if(!row[0]) return;
            return <div className="flex gap-2">
            {row.map((space) => {
                return <div className={`${!space.correct && !space.hasLetterInDifferentSpot && 'bg-zinc-300/30'} ${space.hasLetterInDifferentSpot && 'bg-yellow-300'} ${space.correct && 'bg-green-300'} rounded w-10 h-10 flex items-center justify-center`}>{space.letter.toUpperCase()}</div>
            })}
            </div>
        })}
        <div className="flex gap-3">
            {!board[4][0] && <input value={guess} onKeyPress={({key}) => key == 'Enter' && submitGuess()} placeholder="You got this!" className="focus:outline-none px-4 rounded bg-white/50" onChange={(e) => updateGuess(e.target.value.slice(0, 5))}/>}
            {board[4][0] ? <div onClick={restartGame} className="p-2 px-4 rounded bg-zinc-500/50 text-white cursor-pointer hover:-translate-y-1 transition-all w-full text-center">Try Again!</div>
            : <div onClick={submitGuess} className="p-2 px-4 rounded bg-zinc-500/50 text-white cursor-pointer hover:-translate-y-1 transition-all">Guess</div>}
        </div>
        {error && <div className="bg-red-300/70 flex w-fit items-center justify-center px-3 rounded text-white">{error}</div>}
    </div>
}