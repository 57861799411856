

import { createContext, useContext, useState, useEffect } from 'react';
import { AboutMe } from '../components/AboutMe';
import { Code } from '../components/Code';
import { Comments } from '../components/Comments';
import { Games } from '../components/Games';
import { JobBoard } from '../components/JobBoard';


export interface SafariPage {
    active: boolean;
    node: React.ReactNode;
    fullScreen: boolean;
    name: Pages;
    span?: string;
    code?: boolean;
    note?: Note;
}

export interface Note {
    title: string;
    body: string;
}


interface AppContext {
    safari: SafariPage[],
    notes: Note[],
    user: string,
    safariPreserve: SafariPage[],
}


export interface IApp extends AppContext {
    toggleSafariPage: (p: SafariPage) => void;
    fullScreenSafariPage: (p: SafariPage) => void;
    updateUser: (s: string) => void;
}

export enum Pages {
    COMMENTS = 'Comments',
    CODE = 'VS Code',
    JOBBOARD = 'Job Board',
    ABOUTME = 'About Me',
    TICTACTOE = 'Tic Tac Toe',
    WORDLE = 'Wordle',
    GAMES = 'Games'
}

const openPages = [
    {
        name: Pages.ABOUTME,
        active: true,
        node: <AboutMe/>,
        fullScreen: false,
        note:{title: 'Avilability', body: 'Open to new opportunites'}
    },
    {
        name: Pages.JOBBOARD,
        active: true,
        node: <JobBoard/>,
        fullScreen: false,
    },
    {
        name: Pages.CODE,
        active: true,
        node: <Code/>,
        fullScreen: false,
        code: true,
    },
    {
        name: Pages.COMMENTS,
        active: true,
        node: <Comments/>,
        fullScreen: false,
    },
    {
        name: Pages.GAMES,
        active: true,
        node: <Games/>,
        fullScreen: false,
    },
]

const initalMainState: AppContext = {
    safari: openPages,
    safariPreserve: openPages,
    notes: [],
    user: '',
}

const AppContext = createContext<IApp | undefined>(undefined);


export const AppProvider = ({ children }: { children: React.ReactNode}) => {

    const [state, dispatch] = useState(initalMainState);

    const toggleSafariPage = (page: SafariPage) => {
        let updatedPages;
        if(page.fullScreen) {
            updatedPages = state.safariPreserve;
        } else {
            updatedPages = state.safari.map(p => p.name === page.name ? {...p, active: !page.active} : {...p, fullScreen: false})
        }
        let updateState = updatedPages.sort((a, b) => a.active > b.active ? -1 : 1);
        dispatch({...state, safari: updateState, safariPreserve: updateState})
    }

    const fullScreenSafariPage = (page: SafariPage) => {
        let updatedPages;
        if(page.fullScreen) {
            updatedPages = state.safariPreserve;
        } else {
            updatedPages = state.safari.map(p => p.name === page.name ? {...p, active: true, fullScreen: true} : {...p, fullScreen: false, active: false});
        }
        dispatch({...state, safari: updatedPages})
    }

    const updateUser = (name: string) => {
        dispatch({...state, user: name});
    }

    return (
        <AppContext.Provider value={{ safariPreserve: state.safariPreserve, safari: state.safari, notes: state.notes, toggleSafariPage, fullScreenSafariPage, updateUser, user: state.user}}>
            {children}
        </AppContext.Provider>
    );
}

export function useApp() {
    const context = useContext<IApp | undefined>(AppContext);
    if (context === undefined) {
      throw new Error("useApp must be used within the App Provider");
    }
    return context;
}

