export interface Commission {
    type: CommissionType;
    id: number;
    title: string;
    desc?: string;
    owner: number;
    img?: string;
    likes: number;
    comments?: CommissionComment[]
}

export enum CommissionType {
    WORLDS = "Worlds",
    WORLD_ASSETS = "World Assets",
    AVATARS = "Avatars",
    ANIMATIONS = "Animations",
    SHADERS = "Shaders",
    ART_2D = "2D Art",
    ACCESSIORIES = "Accessiories",
    UDON_SCRIPTING = "Udon Scripting",
    OTHER = "Other"
}

interface CommissionComment {
    owner: number;
    content: string;
}

export const mockComms: Commission[] = [
    {
        type: CommissionType.WORLDS,
        id: 398217361,
        title: 'World creation with UDON Scripting',
        owner: 1,
        desc: 'I create vibrant worlds for VRChat, you can find examples of my work by clicking on my profile!',
        img: 'https://pbs.twimg.com/media/FcN5tcOWIAI_Ko3?format=jpg&name=medium',
        likes: 469,
        comments: [
            {
                owner: 3,
                content: 'Some kind of comment saying how great this commission process was!'
            },
            {
                owner: 4,
                content: 'Well Worth, the artist was very swift and answerd all my questions!'
            }
        ]
    },
    {
        type: CommissionType.WORLDS,
        id: 398217362,
        title: 'Offical VRChat Udon Scripting',
        owner: 2,
        img: 'https://i.ytimg.com/vi/0hu3hjHEqqA/maxresdefault.jpg',
        likes: 72,
        comments: [
            {
                owner: 1,
                content: 'Test Comment'
            }
        ]
    },
    {
        type: CommissionType.AVATARS,
        id: 398217363,
        title: 'Avatar 3.0 Anime Avatar Comms!',
        owner: 5,
        img: 'https://i.ytimg.com/vi/newcvoVZKXE/maxresdefault.jpg',
        likes: 66
    },
    {
        type: CommissionType.AVATARS,
        id: 398217364,
        title: '🎉 Open *FROM SCRATCH* Avatars',
        owner: 5,
        img: 'https://i.ytimg.com/vi/PuQVXG2uqkw/maxresdefault.jpg',
        likes: 33,
        comments: [
            {
                owner: 1,
                content: 'Test Comment'
            },
            {
                owner: 1,
                content: 'Test Comment'
            },
            {
                owner: 1,
                content: 'Test Comment'
            },
            {
                owner: 1,
                content: 'Test Comment'
            },
        ]
    },
    {
        type: CommissionType.ACCESSIORIES,
        id: 398217365,
        title: 'Avatar Sword Accessiories',
        owner: 1,
        img: 'https://booth.pximg.net/7167999c-04b5-4389-8582-b79c2799cc2b/i/3129677/c291bd7e-c0c5-4424-9ccf-55329b418ec9_base_resized.jpg',
        likes: 2
    },
    {
        type: CommissionType.AVATARS,
        id: 398217366,
        title: '🐾 FURSONA COMMISSIONS OPEN',
        owner: 3,
        img: 'https://i.etsystatic.com/22861541/r/il/631738/4247979467/il_fullxfull.4247979467_46a9.jpg',
        likes: 554
    },
    {
        type: CommissionType.ANIMATIONS,
        id: 398217367,
        title: 'Avatar 3.0 Custom Animations',
        owner: 3,
        img: 'https://i.ytimg.com/vi/VlzQYAEM1UE/maxresdefault.jpg',
        likes: 12
    },
    {
        type: CommissionType.ART_2D,
        id: 398217368,
        title: 'Character Sheet Creation',
        owner: 1,
        img: 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/08069cc4-6595-4406-b587-20f2e2027964/desejfp-d0978518-5ef9-427d-a334-1c36150a0ea9.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzA4MDY5Y2M0LTY1OTUtNDQwNi1iNTg3LTIwZjJlMjAyNzk2NFwvZGVzZWpmcC1kMDk3ODUxOC01ZWY5LTQyN2QtYTMzNC0xYzM2MTUwYTBlYTkucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.oTg_uaTuPmMBMXdUXl9JaItbGlxrfTKOy8g9_VAU_Rc',
        likes: 44
    }
    ,
    {
        type: CommissionType.ART_2D,
        id: 398217369,
        title: 'Chibi Character Sheet Commissions',
        owner: 5,
        img: 'https://i.ytimg.com/vi/f72bo8AZV18/maxresdefault.jpg',
        likes: 3
    },{
        type: CommissionType.WORLDS,
        id: 398217311,
        title: 'Brilliant World creation with UDON Scripting',
        owner: 1,
        desc: 'I create vibrant worlds for VRChat, you can find examples of my work by clicking on my profile!',
        img: 'https://pbs.twimg.com/media/FcN5tcOWIAI_Ko3?format=jpg&name=medium',
        likes: 469
    },
    {
        type: CommissionType.WORLDS,
        id: 398217161,
        title: 'Offical VRChat Udon Scripting',
        owner: 2,
        img: 'https://i.ytimg.com/vi/0hu3hjHEqqA/maxresdefault.jpg',
        likes: 72,
        comments: [
            {
                owner: 1,
                content: 'Test Comment'
            }
        ]
    },
    {
        type: CommissionType.AVATARS,
        id: 398417361,
        title: 'Avatar 3.0 Anime Avatar Comms!',
        owner: 5,
        img: 'https://i.ytimg.com/vi/newcvoVZKXE/maxresdefault.jpg',
        likes: 66
    },
    {
        type: CommissionType.AVATARS,
        id: 3982173261,
        title: '🎉 Open *FROM SCRATCH* Avatars',
        owner: 5,
        img: 'https://i.ytimg.com/vi/PuQVXG2uqkw/maxresdefault.jpg',
        likes: 33,
        comments: [
            {
                owner: 1,
                content: 'Test Comment'
            },
            {
                owner: 1,
                content: 'Test Comment'
            },
            {
                owner: 1,
                content: 'Test Comment'
            },
            {
                owner: 1,
                content: 'Test Comment'
            },
        ]
    },
    {
        type: CommissionType.ACCESSIORIES,
        id: 3982174361,
        title: 'Avatar Sword Accessiories',
        owner: 1,
        img: 'https://booth.pximg.net/7167999c-04b5-4389-8582-b79c2799cc2b/i/3129677/c291bd7e-c0c5-4424-9ccf-55329b418ec9_base_resized.jpg',
        likes: 2
    },
    {
        type: CommissionType.AVATARS,
        id: 3982117361,
        title: '🐾 FURSONA COMMISSIONS OPEN',
        owner: 3,
        img: 'https://i.etsystatic.com/22861541/r/il/631738/4247979467/il_fullxfull.4247979467_46a9.jpg',
        likes: 554
    },
    {
        type: CommissionType.ANIMATIONS,
        id: 39821755361,
        title: 'Avatar 3.0 Custom Animations',
        owner: 3,
        img: 'https://i.ytimg.com/vi/VlzQYAEM1UE/maxresdefault.jpg',
        likes: 12
    },
    {
        type: CommissionType.ART_2D,
        id: 393338217361,
        title: 'Character Sheet Creation',
        owner: 1,
        img: 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/08069cc4-6595-4406-b587-20f2e2027964/desejfp-d0978518-5ef9-427d-a334-1c36150a0ea9.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzA4MDY5Y2M0LTY1OTUtNDQwNi1iNTg3LTIwZjJlMjAyNzk2NFwvZGVzZWpmcC1kMDk3ODUxOC01ZWY5LTQyN2QtYTMzNC0xYzM2MTUwYTBlYTkucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.oTg_uaTuPmMBMXdUXl9JaItbGlxrfTKOy8g9_VAU_Rc',
        likes: 44
    }
    ,
    {
        type: CommissionType.ART_2D,
        id: 398218767361,
        title: 'Chibi Character Sheet Commissions',
        owner: 5,
        img: 'https://i.ytimg.com/vi/f72bo8AZV18/maxresdefault.jpg',
        likes: 3
    },
]