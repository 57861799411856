import { useState, useRef, useEffect } from 'react'
import { useApp } from '../context/appContext';
import { pingDiscord } from '../util/discord';
import { timeAgo } from "../util/functions";

interface Comment {
    id: number;
    author: string;
    content: string;
    date: Date;
}

export const Comments = () => {

    const [ newComment, setNewComment ] = useState('');
    const { user } = useApp();
    const scrollRef = useRef(null);

    const [ comments, setComments ] = useState<Comment[]>([
        {id: 1, author: 'Offerpad', content: 'In the first few months you started working here, you hit the ground running and got our entire team on track. ', date: new Date(new Date().setDate(new Date().getDay()-1))},
        {id: 2, author: 'Direct Manager', content: `You've progressed and over achived during your term, you're on track to reach a manager position by the end of the year.`, date:  new Date(new Date().setDate(new Date().getDay()))},
    ])

    const Comment = ({comment}: {comment: Comment}) => {
        return <div className="bg-white/30 rounded p-4 flex gap-1 flex-col w-fit">
            <div className="flex gap-2 items-center justify-start">
                <div className="rounded-full w-10 h-10 bg-stone-800/40 font-bold flex items-center justify-center">{comment.author.substring(0, 2).toUpperCase()}</div>
                <div>
                    <div>{comment.author}</div>
                    <div className="text-xs italic text-stone-800/50">{timeAgo(comment.date)}</div>
                </div>
            </div>
            <div className="lg:max-w-[33vw]">
                {comment.content}
            </div>
        </div>
    }
    const addComment = () => {
        setComments([...comments, {
            id: comments.length + 1,
            author: user,
            content: newComment,
            date: new Date()
        }]);
        setNewComment('')
        pingDiscord({
            title: `${user} left a comment!`,
            content: newComment
        })
    }

    return <div className="flex flex-col gap-3 w-full items-center h-fit rounded">
        <div className="max-h-[30vh] overflow-auto w-full flex flex-col gap-3 p-2">
        {comments.map((comment, i) => {
            return <div className={` ${i % 2 == 0 && i < 2 ? 'bg-green-300/30 self-start' : 'bg-blue-300/30 self-end'} max-w-[75%]`}>
                <Comment comment={comment} />
            </div>
        })}
        <AlwaysScrollToBottom/>
        </div>
        <div className="flex gap-3 w-full items-center justify-center relative">
            {newComment && <div className=" absolute w-[50%] p-2 rounded bg-yellow-300/60 -top-24 left-0">Comments added here are not public, but I will see them when you hit send and if applicable, reach out to you ASAP!</div>}
            <input className="w-full rounded p-2 opacity-[85%] focus:outline-none bg-white/50" value={newComment} onKeyPress={({key}) => key === 'Enter' && addComment()} onChange={e => setNewComment(e.target.value)} placeholder="Add a comment"/>
            <div className="rounded bg-blue-300 p-2 px-4 hover:-translate-y-1 transition-all cursor-pointer" onClick={addComment}>Send</div>
        </div>
    </div>

}

const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    //@ts-ignore
    useEffect(() => elementRef.current?.scrollIntoView());
    //@ts-ignore
    return <div ref={elementRef} />;
  };