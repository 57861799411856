import { Link, useLocation } from 'react-router-dom';
import { SmileyProvider } from '../../context/smileyContext';
import { SmileyHome } from './Home';
import { SmileyMap } from './Map';
import { SmileyNew } from './New';
import { SmileyProfile } from './Profile';
import { SmileySettings } from './Settings';
export const SmileyMain = () => {

    let location = useLocation();
    
    const pages = [
        {
            location: '',
            component: <SmileyHome/>
        },
        {
            location: '/new',
            component: <SmileyNew/>
        },
        {
            location: '/map',
            component: <SmileyMap/>
        },
        {
            location: '/settings',
            component: <SmileySettings/>
        },
        {
            location: '/profile',
            component: <SmileyProfile/>
        },
    ]

    let thisPage = pages.find(page => location.pathname.toLowerCase().includes(page.location) && page.location);
    let homePage = pages.find(page => !page.location && location.pathname.length == 7);

    return <SmileyProvider>
        {thisPage ? thisPage.component : homePage ? homePage.component : null}
    </SmileyProvider>;
}
