import { Link } from "react-router-dom";
import { useSmiley } from "../../context/smileyContext"
import { inventory, testAccount } from "../../mocks/smiley"

export const SmileyHome = () => {

    const {orders} = useSmiley();
    console.log(orders)
    return <div className="w-full p-4 flex flex-col gap-3">
        <div>
            <div className="text-xl font-bold">
                Welcome Back {testAccount.username}!
            </div>
            <div className="text-stone-500">You currently have {orders.length} active orders.</div>
        </div>
        <div className="flex flex-col gap-2">
            {orders.map(order => {
                let thisTruck = inventory.find(i => i.truckID == order.truck);
                if(!thisTruck) return;
                return <div className="w-full bg-stone-200 rounded p-2 flex gap-2 h-20 justify-start">
                    <img src={thisTruck.img} className="rounded object-contain w-20"/>
                    <div>
                        <div className="font-bold">{thisTruck.name}</div>
                        <div className="flex gap-2">
                            <div className="text-blue-400">{order.location}</div>
                            <div className="text-stone-500">- ${thisTruck.cost}/hr</div>
                        </div>
                    </div>
                </div>
            })}
        </div>
        <Link to={'/Smiley/new'} className="bg-blue-300 text-white font-bold p-3 text-center cursor-pointer rounded">Order Another</Link>
    </div>
}