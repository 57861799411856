

import { createContext, useContext, useState, useEffect } from 'react';
import { orders } from '../mocks/smiley';

interface AppContext {
    orders: Order[],
}

export interface Order {
    id: number;
    truck: number;
    location: string;
}


export interface IApp extends AppContext {
    newOrder: (o: Order) => void;
}


const initalMainState: AppContext = {
    orders: orders
}

const AppContext = createContext<IApp | undefined>(undefined);


export const SmileyProvider = ({ children }: { children: React.ReactNode}) => {

    const [state, dispatch] = useState(initalMainState);

    const newOrder = (order: Order) => {
        dispatch({...state, orders: [...orders, order]})
    }

    return (
        <AppContext.Provider value={{ orders: state.orders, newOrder: newOrder }}>
            {children}
        </AppContext.Provider>
    );
}

export function useSmiley() {
    const context = useContext<IApp | undefined>(AppContext);
    if (context === undefined) {
      throw new Error("useApp must be used within the App Provider");
    }
    return context;
}

