import { createBrowserRouter } from "react-router-dom";
import { Main } from './pages/Main'
import { Smiley } from "./pages/Smiley";
import { VRCComms } from "./pages/VRCComms";
import { VRChat } from "./pages/VrChat";
import { VRCUser } from "./pages/VRCUser";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Main/>,
  },
  {
    path: "/VRChat",
    element: <VRChat/>,
  },
  {
    path: "/Smiley",
    element: <Smiley/>,
  },
  {
    path: "/Smiley/new",
    element: <Smiley/>,
  },
  {
    path: "/Smiley/map",
    element: <Smiley/>,
  },
  {
    path: "/Smiley/settings",
    element: <Smiley/>,
  },
  {
    path: "/Smiley/profile",
    element: <Smiley/>,
  },
  {
    path: "/VRChat/comms/:id",
    element: <VRCComms/>,
  },
  {
    path: "/VRChat/user/:name",
    element: <VRCUser/>,
  },
  ]);