//@ts-ignore

import { mockComms } from '../mocks/mockComms';
import { CommissionCard } from '../components/VRC/CommissionCard';
import { VRCBase } from '../components/VRC/Base';
import { useParams } from 'react-router-dom';
import { mockUsers } from '../mocks/mockUsers';

export const VRCUser = () => {
    const { name } = useParams();

    const thisUser = mockUsers.find(e => e.name.toLowerCase() == name?.toLowerCase());
    const userPosts = mockComms.filter(e => e.owner == thisUser?.id);

    if(!thisUser) return <></>
    return <VRCBase>
            <section className="w-full flex flex-col gap-4 overflow-hidden">
                <div className="flex gap-2 items-center">
                    <img className="w-14 h-14 object-contain rounded-full" src={thisUser.avatar}/>
                    <div>
                        <div className="font-bold text-lg">{thisUser.name}</div>
                        <div className="text-sm italic text-zinc-300">{thisUser.bio}</div>
                    </div>
                </div>
                <div className="bg-stone-900 rounded p-2 font-bold">{name}'s Posts</div>
                <div className="grid xl:grid-cols-3 lg:grid-cols-2 gap-3">
                    {userPosts.map(comm => <CommissionCard comm={comm} />)}
                </div>
            </section>
    </VRCBase>
}