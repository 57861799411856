interface DiscordMessage {
    content?: string;
    title: string;
}

const webhookURL = 'https://discord.com/api/webhooks/1070903719924879451/HlKoPAJ1BEWrwz3b3ocjYoQMiCi4UXCdpX9c-tXCuWC5j78tcjkFvRA6uW3NHikh0mEc'


export const pingDiscord = (embed: DiscordMessage) => {
    fetch(webhookURL, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: 'Aisaka',
            embeds: [
              {
                color: 11730954,
                title: embed.title,
                description: embed.content,
              },
            ],
          }),
        }
      );
}