interface Tooltip {
    show: boolean;
    children: React.ReactNode;
}

export const ToolTip: React.FC<Tooltip> = ({show, children}) => {

    if(!show) return <></>;
    return <div className="absolute rounded flex gap-2 w-[fit-content] left-0 right-0 whitespace-nowrap h-[fit-content] p-2">
        <div style={{ transform: 'translateX(-45%) translateY(-140%)'}}>
            {children}
        </div>
    </div>
}