import { useEffect, useState } from "react";

interface HackerTextProps {
    text: string;
    speed?: number;
}

export const HackerText: React.FC<HackerTextProps> = ({ text, speed = 20 }) => {
    const orginalText = text;
    const [textContent, setTextContent] = useState(orginalText);
    useEffect(() => {
        updateRandomChars();
    }, [])

    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';
    
    const updateRandomChars = () => {
        if(orginalText !== orginalText) return;
        let loops = 0;
        const updateLetterInterval: any = setInterval(() => {
            if(loops > orginalText.length) return clearInterval(updateLetterInterval);
            let newText = orginalText.split("").map((letter, i) => {
                const randomChar = Math.floor(Math.random() * chars.length);
                if(i < loops) {
                    return orginalText.split("")[i];
                }
                return chars[randomChar]
            }).join("");
            setTextContent(newText)
            loops += 1
        }, speed)
    }

    return <>{textContent}</>
}