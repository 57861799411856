import { useState } from "react"
import { useApp } from "../context/appContext";
import { SafariScreen } from "./SafariScreen";

interface Job {
    name: string,
    title: string,
    dates: string,
    abr: string,
    color: string,
    desc: string[];
    tags: string[];
}

const currentJobs: Array<Job> = [
    {
        name: 'Offerpad',
        title: 'Sr. Software Engineer',
        dates: 'Oct 2021 - Current',
        abr: 'OP',
        color: 'bg-orange-300',
        desc: [
            'Conducted mutiple interviews for potential Sr. Software Engineer canidates. ',
            'Worked closely with a team, providing code reviews, sprint planning / grooming sessions.',
            'Build robust features for high traffic customer facing web platforms.'
        ],
        tags: ['React', 'Typescript', 'C#', 'Figma', 'Azure', 'Devops', 'SCRUM/Kanban']
    },
    {
        name: 'Logicall Marketing',
        title: 'Software Engineer',
        dates: 'Jan 2018 - Oct 2021',
        abr: 'LM',
        color: 'bg-red-300',
        desc: [
            'Lead Softweare engineer for a small team of developers creating a CRM system built from Larvel / React',
            'Integrated with Twilio, to create a calling / script platform for employees.',
            'Built automated scripts with Python to automate payroll and manual time sheets.'
        ],
        tags: ['React', 'Laravel', 'PHP', 'Python', 'Javascript', 'SCRUM', 'Jira']
    }
]

export const JobBoard = () => {

    const { safari } = useApp();
    const [ jobs, setJobs] = useState(currentJobs);
    const [ newJob, setNewJob ] = useState<boolean>(false);
    const [ newJobInfo, setNewJobInfo ] = useState({
        name: '',
        title: '',
        abr: '',
        desc: [],
        tags: []
    })

    const addJob = () => {
        setJobs([{...newJobInfo, color: 'bg-blue-300', dates: 'Starting Now'}, ...jobs])
        setNewJobInfo({name: '', title: '', abr: '', desc: [], tags: []});
        setNewJob(false);
    }

    const thisPage = safari.find(p => p.name == 'Job Board')
    
    return <>
        <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-3">
                {jobs.map((job, i) => {
                    let hasBottomBorder = jobs.length - 1 !== i;
                    return <div className={`flex flex-col ${hasBottomBorder && 'border-b border-white/20 pb-2'} gap-3`} key={job.name}>
                        <div className="flex gap-2 items-center justify-start">
                            <div className={`rounded-full p-2 ${job.color} font-bold w-10 h-10 flex items-center justify-center`}>{job.abr}</div>
                            <div className="flex flex-col">
                                <div className=" text-gray-900 font-semibold">{job.name} <span className="text-gray-600 italic text-xs">{job.dates}</span></div>
                                <div className="text-sm">{job.title}</div>
                            </div>
                        </div>
                        {thisPage && thisPage.fullScreen && <div className="flex flex-col gap-2">
                            {!!job.desc.length && <><div className="text-lg font-semibold text-stone-800/90">Highlights</div>
                                {job.desc.map(entry => {
                                    return <div className="bg-stone-800/10 rounded p-2 w-fit text-white ml-4">{entry}</div>
                                })}
                            </>}
                            {!!job.tags.length && <><div className="text-lg font-semibold text-stone-800/90">Tags</div>

                            <div className="flex gap-1">
                                 {job.tags.map(entry => {
                                    return <div className="bg-purple-600/30 rounded px-2 py-1 w-fit text-white">{entry}</div>
                                })}
                            </div></>}
                        </div>}
                    </div>
                })}
            </div>
            {newJob && <div className="flex flex-col gap-2">
                <input 
                    placeholder={'Enter Company Name'} 
                    className="w-full p-2 bg-white/30 outline-none border-none rounded" 
                    onChange={e => setNewJobInfo({...newJobInfo, name: e.target.value})}/>
                <div className="flex gap-2">
                    <input 
                        placeholder={'Job Title'} 
                        className="w-full p-2 bg-white/30 outline-none border-none rounded"
                        onChange={e => setNewJobInfo({...newJobInfo, title: e.target.value})}/>
                    <input 
                        placeholder={'Abbreviation'} 
                        className="p-2 bg-white/30 outline-none border-none rounded"
                        onChange={e => setNewJobInfo({...newJobInfo, abr: e.target.value})}/>
                </div>
            </div>}
            <div className="flex gap-2">
                <div className="bg-white/20 w-full text-center p-2 rounded cursor-pointer hover:bg-white/40 hover:text-gray-400 transition-all"
                    onClick={() => setNewJob(!newJob)}>{newJob ? 'Cancel' : 'Add your company'}</div>
                {newJob && <div className="bg-green-300/80 text-white w-full text-center p-2 rounded cursor-pointer hover:bg-green-400/100 transition-all"
                    onClick={addJob}>Add Company</div>}
            </div>
        </div>
    </>
}