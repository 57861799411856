import { useState } from "react";
import { useSmiley } from "../../context/smileyContext"
import { inventory } from "../../mocks/smiley";

export const SmileyNew = () => {

    const { orders, newOrder } = useSmiley();
    const [ newOrderInfo, setNewOrderInfo ] = useState();
    return <div className="w-full p-3">
        <div className="text-xl font-bold">What Crane are you looking for?</div>
        <div className="flex gap-3 flex-col">
        {inventory.map(i => {
            return <div className="w-full h-[25vh] bg-stone-200 rounded p-2">
                <img src={i.img} className="w-full object-cover rounded h-[50%] object-center"/>
                <div className="font-bold">{i.name}</div>
            </div>
        })}
        </div>
    </div>
}