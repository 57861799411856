import { useEffect, useState } from "react"
//@ts-ignore
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { useApp } from "../context/appContext";
import { codeTheme } from "../util/codeEditor";

const exampleCode = `
//React v18 Component Example

//Typescript interfaces make dealing with components exceptionally easy in modern React.
interface Employee {
    id: number;
    name: string;
    occupation: string;
    jobSearching: boolean;
}

interface EmployeeProps {
    employee: Employee;
}

const EmployeeCard: React.FC<EmployeeProps> = ({employee}) => {
    const isJobSearching = employee.jobSearching;

    //Notice the classnames, on a personal level I prefer using Tailwind!
    return <div className="p-2 rounded bg-white/30">
        {isJobSearching ? 'Hire' : 'Contact'} {employee.name}!
    </div>;
}

export const Employee: React.FC = () => {
    const employee: Employee = {
        id: 314, 
        name: 'Philip Cummings',
        occupation: 'Sr. Software Engineer',
        jobSearching: true
    }

    return <div className="rounded bg-stone-600">
        <div className="text-sm">{employee.id}</div>
        <EmployeeCard employee={employee}/>
    </div>;
}
`


export const Code = () => {


    const initalCode = `const newEmployee = 'Philip Cummings';\r\nconst currentCompany = 'Offerpad';\r\n\r\nconst languages = ['react', 'typescript', 'C#']`
    const { safari, fullScreenSafariPage } = useApp();
    const [code, setCode] = useState(initalCode);
    const thisPage = safari.find(p => p.name == 'VS Code');
    useEffect(() => {
        if(!thisPage) return;
        if(thisPage.fullScreen) setCode(exampleCode);

        return () => setCode(initalCode)
    }, [thisPage])
    if(!thisPage) return <></>;

    return <>
        <div className="flex flex-col gap-3 focus:outline-none overflow-auto">
            <SyntaxHighlighter
            language={'javascript'}
            style={codeTheme}
            className="break-words !bg-transparent"
            >
                {code}
            </SyntaxHighlighter>
            {!thisPage.fullScreen && <div className="text-xs text-white/40 cursor-pointer max-w-[100%] overflow-auto" onClick={() => fullScreenSafariPage(thisPage)}>View example code written by me...</div>}
        </div>
    </>
}